import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
const Styled = styled.div`
.selectV > div:first-of-type {
    height: 50px !important;
    border: none !important;
    background-color: #f0f3f5 !important;
    border-radius: 10px;
    box-shadow: none !important;
}
.css-1hb7zxy-IndicatorsContainer {
    display: none;
}
.Input {
    width: 85% !important;

}
.SelectV {
    display: flex;
    width: 100% !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #f0f3f5;
    border-radius:10px ;
}
.Icon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}
`;
const SelectDesing = (props) => {
  return (
    <Styled>
        <div className='SelectV'>
            <div className='Icon'>
                {props.icon}
            </div>
            <div className='Input'>
            <Select   placeholder={props.label}  className='selectV'  la  theme={(theme) => ({
      ...theme,
      borderRadius: 0,
      backgroundColor: "#f0f3f5",
      colors: {
        ...theme.colors,
        primary25: '#f0f3f5',
        primary: 'black',

      },
    })} options={props.options} />
            </div>

        </div>

    </Styled>
  )
}

export default SelectDesing