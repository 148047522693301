import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Styled = styled.div`
max-width: 500px;
margin: auto;
.imgs{
    width: 65px;
    display: block;
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 10px;
    height: 65px;
}
.df a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 2px;
    border: solid 1px #ccc;
    border-radius: 10px;

}
span {
    font-size: 20px;
}
a {
    text-decoration: none;
    color: #1e1e1e;
}
a.Selected {
    border-color: #42d495 !important;
}
`;

const SelectType = () => {
const [option,Setoption] = useState(
    [
    {
        id:1,
        name:"Apartement",
        imag:"https://www.indexsante.ca/chroniques/images/appartement-residence.jpg"
    },
    {
        id:2,
        name:"House",
        imag:"https://images.adsttc.com/media/images/629f/3517/c372/5201/650f/1c7f/large_jpg/hyde-park-house-robeson-architects_1.jpg"
    
    },
    {
        id:3,
        name:"Studio",
        imag:"https://cache.marieclaire.fr/data/photo/w680_h340_ci/69/grand-salon-blanc1.jpg"
    }]
);
const [Select,setSelect] = useState(1);
   return (
     <Styled>
        <Row
        style={{
            textAlign:"start"
        }}
        >
        {
            option.map((item,i)=><Col className='df' lg={12} key={i}>
                <a onClick={(e)=>setSelect(item.id)} className={Select==item.id?'Selected':''}>
                    <span>{item.name}</span><img className='imgs' src={item.imag}/>
                </a>
            </Col>)
        }
        </Row>
     </Styled>
   )
}

export default SelectType