import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
const Styled = styled.div`
h2{
    text-align: center;
}
label.form-label{
font-size: 20px;
font-weight: 800;
}
input {
    background-color: #f0f3f5 !important;
    border: none;
    height: 60px;
}
textarea.form-control-lg{
    background-color: #f0f3f5 !important;
    border: none;
}
span#basic-addon2 {
    border: none;
    background-color: #f0f3f5 !important;
}
max-width: 800px;
    margin: auto;
`;

const SectionDetails = () => {
  return (
    <Styled>
        <h2>Let's give your place mor informations</h2>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Create your title here</Form.Label>
        <Form.Control  size="lg" type="text" placeholder="Type your titel here" />
          </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Creat your desription</Form.Label>
        <Form.Control size="lg" as="textarea" rows={5} />
      </Form.Group>
      <Form.Label>Set your price</Form.Label>
      <InputGroup className="mb-3">
        
        <Form.Control
        size="lg"
          placeholder="4000"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          type='number'
        />
        <InputGroup.Text id="basic-addon2">DA</InputGroup.Text>
      </InputGroup>

    </Styled>
  )
}

export default SectionDetails