import React from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import MapStyle from '../style/MapStyle';
import styled from 'styled-components';
const Styled = styled.div`
.gm-style-iw.gm-style-iw-c {
    padding: 0;
    margin: auto;
    background-color: "#42d495";
}
.gm-style-iw-d {
    background-color: #42d495;
    display: contents;
}
h3 {
    margin: 0;
    padding: 3px 23px;
    color: #fff;
    font-size: 18px;
}
button.gm-ui-hover-effect {
    display: none !important;
}
.gm-style .gm-style-iw-tc::after {
    background: #42d495;
    clip-path: polygon(0% 0%,50% 100%,100% 0%);
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 25px;
}
`;
function MapInput(props) {
    const center = {
        lat: 36.10558834529647,
        lng:  2.0177565088515323
      };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD-bDlpWjCErwQuULwtNPv89ROKo0DLkTo",
    })
    return isLoaded ? (
        <Styled><GoogleMap
        mapContainerStyle={{
            width: '100%',
            height: props.height?props.height+'px':'100vh',
            borderRadius:props.height?"10px":"0"
        }}
        options={{
            styles: MapStyle["c"],
            fullscreenControl: false, zoomControl : false,
            mapTypeControl: false
        }}
        center={center}
        zoom={9}
    >
        {
            props.Pos?props.Pos.map((x,i)=>x.lat?<InfoWindow
      
            position={{ lat: x.lat, lng: x.lng }}
          >
            <div style={{
background: `#42d495`
}}>
              <h3>{x.price} {"DA"}</h3>
            </div>
          </InfoWindow>:<></>):<></>
        }
        

    </GoogleMap></Styled>
    ) : <></>
}

export default React.memo(MapInput)