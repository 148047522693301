import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Styled = styled.div`
max-width: 500px;
margin: auto;
.imgs{
    width: 65px;
    display: block;
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 10px;
    height: 65px;
}
.df >a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin: 10px 0;
    border: solid 1px #ccc;
    border-radius: 10px;

}
span {
    font-size: 20px;
}
a {
    text-decoration: none;
    color: #1e1e1e;
}
a.Selected {
    border-color: #42d495 !important;
}
.Reglag a{
    padding: 1px 8px;
    border: #1e1e1e solid 1px;
    border-radius: 20px;
}
.Reglag{
    display: flex;
    width: 100px;
    justify-content: space-around;
    align-items: center;
}
`;

const MultiSelect = () => {
const [option,Setoption] = useState(
    [
    {
        id:1,
        name:"Guests",
        count:1
    },
    {
        id:2,
        name:"Beds",
        count:1
    
    },
    {
        id:3,
        name:"Bedrooms",
        count:1
    }

]
);
const [Select,setSelect] = useState(1);
   return (
     <Styled>
        <Row
        style={{
            textAlign:"start"
        }}
        >
        {
            option.map((item,i)=><Col className='df' lg={12} key={i}>
                <a>
                    <span>{item.name}</span><div className='Reglag'>
                        <a>+</a> {item.count} <a>-</a>
                    </div>
                </a>
            </Col>)
        }
        </Row>
     </Styled>
   )
}

export default MultiSelect