import React from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import MapStyle from '../style/MapStyle';
import styled from 'styled-components';
const Styled = styled.div`

`;
function InputMap2(props) {
    const center = {
        lat: 36.10558834529647,
        lng: 2.0177565088515323
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD-bDlpWjCErwQuULwtNPv89ROKo0DLkTo",
    })
    return isLoaded ? (
        <Styled><GoogleMap
            mapContainerStyle={{
                width: props.width ? props.width : '100%',
                height: props.height ? props.height + 'px' : '100vh',
                borderRadius: props.height ? "10px" : "0"
            }}
            options={{
                styles: MapStyle["c"],
                fullscreenControl: false, zoomControl: false,
                mapTypeControl: false
            }}
            center={center}
            zoom={7}
            onClick={(palce) =>
                props.Setposition({
                    lat: palce.latLng.lat(),
                    lng: palce.latLng.lng()
                })
            }
        >

            {
                props.position.lat && props.position.lng ? <>
                    <Marker
                        position={{
                            lat: props.position.lat,
                            lng: props.position.lng
                        }}
                    />

                </> : <></>
            }



        </GoogleMap>

        </Styled>
    ) : <></>
}

export default React.memo(InputMap2)