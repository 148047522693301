import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import formImag from '../imag/form.jpg'
import { DateRangePicker, View } from '@adobe/react-spectrum'
import { defaultTheme, Provider } from '@adobe/react-spectrum';
import { FiMapPin } from "react-icons/fi";
import wilayas from '../data/wilaya.json'
import SelectDesing from './SelectDesing';
import SelectOptions from './SelectOptions';
import { FiSearch } from "react-icons/fi";
import { Link } from 'react-router-dom';
const Styled = styled.div`
 height:540px;
.Walpp {
    background-image: url(${formImag});
    max-height:540px;
    height: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.CardForm {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    background-color: #fff;
    padding: 25px;
    border-radius: 20px;
}
h1{
    font-weight: 600;
    font-size: 30px;
}
/*.spectrum--medium_4b172c{
font-family: var(--font_globale) !important;
background-color: transparent !important;


}
/*.spectrum--medium_4b172c >div:first-of-type {
    width: 100%;
}
/*[dir=ltr] .react-spectrum-Datepicker-input_f02f90.react-spectrum-Datepicker-input_f02f90{
    border-radius: 8px;
    height: 50px;
    padding-left: calc(var(--spectrum-textfield-padding-x,var(--spectrum-global-dimension-size-150)) + var(--spectrum-icon-alert-medium-width,var(--spectrum-global-dimension-size-225)) + var(--spectrum-textfield-icon-margin-left,var(--spectrum-global-dimension-size-150)));
    padding-right: 8px;
    background-color: #f0f3f5;
    color: #000 !important;
    font-size: 16px;
    font-weight: 500;
    border: none;


}
/*[dir=ltr] .spectrum-InputGroup_a0942f .spectrum-FieldButton_a0942f{
    position: absolute;
    right: auto;
    top: 8px;
    background-color: transparent;
    border: none;
    left: 8px;

}
/*.spectrum-Calendar-date_6099b6.is-selected_6099b6:not(.is-range-selection_6099b6), .spectrum-Calendar-date_6099b6.is-selection-start_6099b6, .spectrum-Calendar-date_6099b6.is-selection-end_6099b6{
    background-color: #42d495 !important;
    
}*/
.svg{
    width: 23px;
    height: 23px;
    color: black;

}

/*.selectV > div:first-of-type {
    height: 50px !important;
    border: none !important;
    background-color: #f0f3f5 !important;
    border-radius: 10px !important;
}*/

.btnSerch{
    padding: 15px 20px;
    background-color: #42d495;
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}
.btnSerch svg{
    margin-right: 5px;
    width: 23px;
    height: 23px;
    margin-top: auto;

}
@media only screen and (max-width: 1250px) {
    .btnSerch{
    padding: 15px 20px;
    background-color: #42d495;
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
}
.btnSerch svg{
    margin-right: 5px;
    width: 20px;
    height: 20px;
    margin-top: auto;

}

}
@media only screen and (max-width: 768px) {
    .CardForm {
        width: 90%;
    }
    .align-items-center.row >div {
    padding: 10px;
}
a.btnSerch {
    width: -webkit-fill-available;
    max-width: 100%;
    display: block;
    text-align: center;
}
}
.spectrum-InputGroup_a0942f{
    width: 100% !important;
    background-color: transparent;
   
}
[dir=ltr] .spectrum-InputGroup-input_a0942f{
    border: none;
    height: 45px;

}
.sectionInput div{
    background-color: #f5f5f5;
    border-radius: 8px !important;
    border: solid 1px #f5f5f5;
    display: inline-flex;

}
.sectionInput {
    background-color: #fff;
}
button{
    border: none !important;
    background-color: transparent;
    margin-top: 7px;
}
`;
const FormSerche = () => {
    return (
        <Styled>
            <div className='Walpp'>
                <Container>
                    <div className='CardForm'>
                        <h1>Find your next stay</h1>
                        <p>Search low prices on Apartments,homes and much more ...</p>
                        <Row className="align-items-center" >
                             <Col sm={6} lg={3}>
                             <SelectDesing  icon={<FiMapPin/>} label={"Wilaya"} options={wilayas}/>
                            </Col>
                            <Col sm={6} lg={3}>
                                {/*
                                <Provider colorScheme="light" theme={defaultTheme}>
                                <View

                                
                                ><DateRangePicker className="DatePicker" /></View>
    </Provider>*/}
        <Provider colorScheme="light" theme={defaultTheme}>
                        <div className='sectionInput'><DateRangePicker className="DatePicker" />
                        </div>
                    </Provider>
                            </Col>
                            <Col sm={3} lg={2}>
                            <SelectOptions name='Rooms'/>
                            </Col>
                            <Col sm={3} lg={2}>
                            <SelectOptions name='Geusts'/>
                            </Col>
                            <Col sm={3} lg={2}>
                                <Link to ={"/search"} className='btnSerch'><FiSearch/>Search</Link>
                            </Col>
                        </Row>

                    </div>

                </Container>
            </div>

        </Styled>
    )
}

export default FormSerche