import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AiFillStar } from "react-icons/ai";
import { DateRangePicker, Provider, View } from '@adobe/react-spectrum';
import { defaultTheme } from '@adobe/react-spectrum';
import SelectOptions from './SelectOptions';
import { FiMapPin } from "react-icons/fi";
import { Link } from 'react-router-dom';
const Styled = styled.div`
padding: 10px;
border: solid 1px #ccc;
border-radius: 10px;
.er{
    text-align: end;
}
.Price {
    display: flex;
    align-items: center;

}
.Price h2{
    font-weight: 900;
    padding: 5px;
}

.spectrum-InputGroup_a0942f{
    width: 100% !important;
    background-color: transparent;
   
}
[dir=ltr] .spectrum-InputGroup-input_a0942f{
    border: none;
    height: 45px;
    background-color: transparent;

}
button{
    border: none !important;
    background-color: transparent;
    margin-top: 7px;
}
.sectionInput{
    background-color: #f5f5f5;
    border-radius: 10px;
    border: solid 1px #ccc;
    padding: 5px;
}
.btnSerch{
    padding: 15px 40px;
    background-color: #42d495;
    border-radius: 10px;
    color: #fff;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100% !important;
    display: block;
    text-align: center;
    font-size: 20px;
}
.col-lg-12.col-12 {
    padding: 10px 15px;
}
img {
    display: block;
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 10px;
    width: 180px;
    height: 140px !important;

}
.sdd{
    font-size: 30px;
    font-weight: 900;
}
.HG{

}
.priceA {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
h2.HG {
    font-weight: 900;
}
.priceA h2 {
    font-weight: 800;
    font-size: 25px;
}
`;

const FormReserve = (props) => {
    return (
        <Styled>
            <Row style={{
                alignItems: "center",
                padding: props.chek ? 20 : "auto"
            }}>

                {props.chek ? <>
                    <Col lg={4} xs={12} md={4}>
                        <img src='https://a0.muscache.com/im/pictures/miso/Hosting-52527983/original/307a85dd-3794-4efc-a57c-49102830e141.jpeg' />
                    </Col>
                    <Col lg={8} xs={12} md={8}>
                        <h2 className='sdd'>Bungalow Angolo Dell rose</h2>
                        <Row>
                            <Col lg={6} xs={6} >
                                <span className='Star'><FiMapPin /> Alger </span>
                            </Col>
                            <Col lg={6} xs={6} >
                                <span className='Star'><AiFillStar /> 4.7</span>
                            </Col>
                            <Col lg={12} xs={12}>
                                <div className='Price'><h2>4800 DA </h2><span> night</span></div>
                            </Col>
                        </Row>
                        <br />
                    </Col>

                </> : <><Col lg={6} xs={6}>
                    <div className='Price'><h2>4800 DA </h2><span> night</span></div>
                </Col>
                    <Col lg={6} xs={6} className={"er"}>
                        <span className='Star'><AiFillStar /> 4.7</span>
                    </Col>
                </>}

                <Col lg={12} xs={12}>
                    <Provider colorScheme="light" theme={defaultTheme}>
                        <div className='sectionInput'><DateRangePicker className="DatePicker" />
                        </div>
                    </Provider>
                </Col>
                <Col lg={12} xs={12}>
                    <Row style={{
                        marginTop: 10,
                        marginBottom: 10
                    }}>
                        <Col lg={6}>
                            <SelectOptions name='Rooms' />
                        </Col>
                        <Col lg={6}>
                            <SelectOptions name='Geusts' />
                        </Col>
                    </Row>
                </Col>

                {props.chek ? <Col lg={12} xs={12}>
                    <h2 className='HG'>Price detail</h2>
                    <div className='priceA'>
                        <span>4800 DA X 5 nights</span>
                        <h2>24000 DA</h2>
                    </div>
                </Col> : <></>}

                <Col lg={12} xs={12}>
                    <Link to={"/Checkout"} className='btnSerch'>Reserve</Link>
                </Col>
            </Row>

        </Styled>
    )
}

export default FormReserve