
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FormSerchF from '../widget/FormSerchF';
import Header from '../widget/Header';
import MapInput from '../widget/MapInput';
import ListCard from '../data/FakeDataHouse.json'
import styled from 'styled-components';
import CardHouse from '../widget/CardHouse';
import FakeData from '../data/FakeDataHouse.json'
const Styled = styled.div`
.AllRest{
  max-height: 100%;
  overflow:hidden;
}
.fgleft.row {
    padding: 0px 40px 0 0;
    max-width: 681px;
    margin-left: auto;
    overflow-y: scroll;
    height: 100vh;
}
.fgleft.row::-webkit-scrollbar {
  display: none;
}

`;


const Search = () => {
  return (
    <Styled>
        <FormSerchF/>
        <div className='AllRest'>
          <Row> 
            <Col lg={6} xs={12}>
            <Row className='fgleft'> 
              {
                 ListCard.map((item,i)=>i<8?<Col lg={6} md={6} xs={6} key={i}><CardHouse data={item}/></Col>:null)
              }
            </Row> 
            </Col>
            <Col className='HiddenMap' lg={6} xs={12}>
              <MapInput Pos={FakeData}/>
            </Col>
          </Row>

        </div>
    </Styled>
  )
}

export default Search