import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import StyleHeroText from './StyleHeroText';
import ListCard from '../data/FakeDataHouse.json'
import CardHouse from './CardHouse';

const Styled = styled.div`
margin: 60px 0;
`;

const SectionFirset = () => {
  return (
    <Styled>

        <Container>
            <StyleHeroText text ={"Top popular appartements,Houses"} />
            <Row>
                {
                ListCard.map((item,i)=>i<8?<Col lg={3} md={4} xs={6} key={i}><CardHouse data={item}/></Col>:null)
                }
            </Row>
        </Container>
    </Styled>
  )
}

export default SectionFirset