import Home from "./view/Home";
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Search from "./view/Search";
import Login from "./view/Login";
import Layout from "./view/Layout";
import House from "./view/House";
import Hoster from "./view/Hoster";
import Checkout from "./view/Checkout";
function App() {
  return (
    <>
    <BrowserRouter>
     <Routes>
     <Route path="/" element={<Layout><Home /></Layout>}/>
     <Route path="/search" element={<Layout><Search /></Layout>}/>
     <Route path="/Login" element={<Layout><Login /></Layout>}/>
     <Route path="/House" element={<Layout><House /></Layout>}/>
     <Route path="/Hoster" element={<Layout><Hoster /></Layout>}/>
     <Route path="/Checkout" element={<Layout><Checkout /></Layout>}/>
    </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
