import React from 'react';
import styled from 'styled-components';
import { FiMapPin } from "react-icons/fi";
import { Col, Container, Row } from 'react-bootstrap';
import { FiShare,FiHeart } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";
import { FaShower,FaWifi,FaUtensils,FaTv,FaParking,FaWind } from "react-icons/fa";
import FormReserve from '../widget/FormReserve';
import MapInput from '../widget/MapInput';
const Styled = styled.div`
font-family: var(--font_globale) !important;
.SectionDetail{
  padding: 15px 30px;
    background-color: #f5f5f5;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.IconsShar svg {
    width: 45px;
    height: 45px;
    padding: 10px;
    background-color: #fff;
    margin: 0 5px;
    border-radius: 25px;
}
.fg {
    max-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Star svg{
  width: 20px;
    height: 20px;
    color: #ffbe0d;
}
border-top: solid 1px #ccc;
padding: 25px 0;
img {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
}
.ImagGallry.row {
    padding: 20px 0;
    min-height: 500px;
}
h3 {
  font-size: 18px;
    font-weight: 700;
}
img.userPng {
    max-width: 50px;
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-left: auto;
}
.df{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  height: 100%;
}
li {
    list-style: none;
}
.AllOptions {
    height: 200px;
    padding-top: 30px;
}
.mapView{
  margin-top: 50px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  h2{
    font-size: 16px;
  }
  .SectionDetail {
    padding: 10px 10px !important;
}
.IconsShar {
    display: flex;
}
}

`;
const House = () => {
  return (
     <Styled>
      <Container>
        <div className='SectionDetail'>
        <div className='Left'>
           <h2>Tiny House with unique mountain views</h2>
           <div className='fg'>
              <span><FiMapPin/> Alger</span>   <span className='Star'><AiFillStar/> 4.6</span> 
          </div>
        </div>
        <div className='IconsShar'>
          <a>
            <FiShare/>
          </a>
          <a>
          <FiHeart/>
          </a>
        </div>
      </div>
      <Row className='ImagGallry'>
        <Col lg={6}>
          <img src='https://a0.muscache.com/im/pictures/miso/Hosting-52527983/original/307a85dd-3794-4efc-a57c-49102830e141.jpeg'/>
        </Col>
        <Col lg={6}>
         <Row style={{
          height:'100%'
         }}>
         <Col style={{
          padding:'5px'
         }} lg={6}>
         <img src='https://a0.muscache.com/im/pictures/miso/Hosting-52527983/original/307a85dd-3794-4efc-a57c-49102830e141.jpeg'/>
          </Col>
          <Col  style={{
          padding:'5px'
         }} lg={6}>
          <img src='https://a0.muscache.com/im/pictures/miso/Hosting-52527983/original/307a85dd-3794-4efc-a57c-49102830e141.jpeg'/>
          </Col>
          <Col style={{
          padding:'5px'
         }} lg={6}>
          <img src='https://a0.muscache.com/im/pictures/miso/Hosting-52527983/original/307a85dd-3794-4efc-a57c-49102830e141.jpeg'/>
          </Col>
          <Col style={{
          padding:'5px'
         }} lg={6}>
          <img src='https://a0.muscache.com/im/pictures/miso/Hosting-52527983/original/307a85dd-3794-4efc-a57c-49102830e141.jpeg'/>
          </Col>
        </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={6} xs={12}>
          <Row style={{
            justifyContent:"space-between",
            
          }}>
          <Col lg={8} xs={10}>
            <h3>Tiny House with unique mountain views</h3>
            <div className='OptionsRom'>
              <span>2 Guest  - </span>
              <span>1 Bedroom  - </span>
              <span>1 Bed  - </span>
              <span>2 Sharedbathd </span>
            </div>
            <div className='AllOptions'>
              <ul className='df'>
                <li><FaShower/> Shower</li>
                <li><FaWifi/> Wifi</li>
                <li><FaUtensils/>Chiken </li>
                <li><FaTv/> Tv</li>
                <li><FaParking/> Garage</li>
                <li><FaWind/> Air cooler</li>
              </ul>

            </div>
          </Col>
          <Col lg={4} xs={2}>
            <img className='userPng' src='https://a0.muscache.com/im/pictures/user/3bfb4fa6-94ec-4354-b3e5-18f255a24893.jpg'/>
          </Col>
          </Row>
        </Col>
        <Col lg={6} xs={12}>
          <FormReserve/>

        </Col>
        <Col lg={12} xs={12}>
          <div className='mapView'>
          <MapInput  Pos={[{ lat:36.741576719807746 ,lng:3.0120904984419896 , price:4800  }]} height={400}/>
            </div>
        </Col>
      </Row>
      </Container>
     </Styled>
  )
}
export default House