
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components'
import imag from '../imag/logo3.png';
import play from '../imag/Google Play PNG.png';
import App from '../imag/App Strore Png.png';
const Styled = styled.div`
background-color: #303030;
padding: 5% 0 0 0;
.container{
height: 250px;
color: #fff;
}
.logo{
  filter: brightness(0) invert(1);
}
p.desFooter {
  margin-top: 30px;
    font-size: 14px;

}
a {
    text-decoration: none;
    color: #fff;
}
li {
    list-style: none;
    font-size: 18px;
}
ul {
    margin: 0;
    padding: 0;
    margin-top: 30px;
}
h2{
  color: #fff;
  font-size: 25px;
}
.Info a{
  font-size: 16px !important;
}
p.copyR {
  text-align: center;
    margin: 0;
    color: #fff;
    font-size: 12px;
    padding-bottom: 13px;
}
@media only screen and (max-width: 768px) {
  .container{
height: auto;
color: #fff;
}

}

`;

const Footer = () => {
  return (
    <Styled>
      <Container>
        <Row style={{
          alignItems: "center"
        }}>
          <Col xs={12} lg={4}>
            <img className='logo' height={40} src={imag} />
            <p className='desFooter'>
            Achouche Tours provides a service that allows people to rent accommodation of various types for a short period of time for the purpose of rest, vacation and tourism.
            </p>
          </Col>
          <Col xs={12} lg={2}>
            <h2>Informations</h2>
            <ul>
              <li><a href="#">Contact us</a></li>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>


          </Col>

          <Col xs={12} lg={3}>
            <ul className='Info'>
              <li><a href="#">Addresse : Cité Gasria,Sétif </a></li>
              <li><a href="#">Phone : +213 663 17 17 21</a></li>
              <li><a href="#">Email : achouchetours@gmail.com</a></li>
            </ul>
          </Col>

          <Col xs={12} lg={3}>
            <h2>Download my application</h2>
            <Row>
              <Col xs={6} lg={6}>
                <a><img height={40} src={play} /></a>
              </Col>
              <Col xs={6} lg={6}>
                <a><img height={40} src={App} /></a>
              </Col>
            </Row>

          </Col>

        </Row>

      </Container>
      <p className='copyR'>Copyright © 2022 AchoucheTours.com </p>
    </Styled>
  )
}

export default Footer