import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import logo from '../imag/logo3.png';
import { HiOutlineTranslate } from "react-icons/hi";
import { Link } from 'react-router-dom';
const Styled = styled.div`
nav.navbar {
    padding: 25px 0;
    align-items: center;
}
a.btnS {
    padding: 10px;
    background-color: var(--main_color);
    color: #fff;
    border-radius: 30px;
    width: 100px;
    text-align: center;
    margin: 0px 0px 0 10px;
}
a.lngSwitch.nav-link svg {
    width: 30px;
    height: 26px;
    margin: 0 0 0 5px;
}
@media only screen and (max-width: 768px) {
.ms-auto.navbar-nav {
    display: none;
}
}
`;

const Header = () => {
    return (
        <Styled>
            <Navbar sticky='top'>
                <Container>
                    <Navbar.Brand href="/">
                        <img height={"40px"} src={logo} />
                    </Navbar.Brand>
                    <Nav className="ms-auto">
                        <Nav.Link as={Link} to="/Hoster">Become a Host</Nav.Link>
                        <Nav.Link as={Link} to={"/Login"} className='btnS btn_login'>
                            Login
                        </Nav.Link>
                        <Nav.Link href='#' className='lngSwitch'>
                            <HiOutlineTranslate /><span>ENG</span>
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </Styled>
    )
}

export default Header