import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HosterImag from '../imag/hoster.jpg'
import MultiSelect from '../widget/MultiSelect';
import MultiUploud from '../widget/MultiUploud';
import SectionDetails from '../widget/SectionDetails';
import SelectBoxChous from '../widget/SelectBoxChous';
import SelectPointPosition from '../widget/SelectPointPosition';
import SelectType from '../widget/SelectType';
const Styled = styled.div`
#background {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 50%;
    height: 100%;
    background:url(${HosterImag});
    z-index: 1;
    background-origin: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.SectionContent{
  position: absolute;
  z-index: 2;
  padding: 30px;
  top: 40%;
}

.Hero {
    max-width: 400px;
}
h1 {
    font-size: 50px;
    font-weight: 800;
}
p{
  font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.btn_go{
  padding: 15px 60px;
    background-color: #42d495;
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    border-radius: 42px;
}
 .btn_ {
    padding: 15px 50px;
    font-size: 16px;
    text-decoration: none;
    background: #ebebeb;
    border-radius: 30px;
    color: #505050;
}
.btnNext.btn_ {
    background-color: #42d495;
    color: #fff;
}
.ContentSection{
  padding: 80px 0;
}


`;
const Hoster = () => {
  const navigate = useNavigate();
  const [Steps, SetSteps] = useState(0);
  const [DataHoster, SetDataHoster] = useState({
    select: 1
  });
  return (
    <Styled>
      {Steps == 0 ? <><Container>
        <div className='SectionContent'>
          <div className='Hero'>
            <h1>Become a Hoster in 10 easy steps</h1>
            <p>Join us.We'll help you every step of the way.</p>
            <a className='btn_go' onClick={(e) => SetSteps(1)}> Let's go</a>
          </div>
        </div>
      </Container>
        <div id="background">
        </div></> : <>
        <Container>
          <div className='ContentSection'>
            {Steps == 1 ? <>
              <SelectType />
            </> : null}

            {Steps == 2 ? <>
              <SelectBoxChous
                titel={"Do you have any standout amenities ?"}
                data={[
                  {
                    id: 1,
                    name: "pool"
                  },
                  {
                    id: 2,
                    name: "Hot tub"
                  },
                  {
                    id: 3,
                    name: "Patio"
                  },
                  {
                    id: 4,
                    name: "BBQ Grill"
                  },
                  {
                    id: 5,
                    name: "Fire Pit"
                  },
                  {
                    id: 6,
                    name: "Pool Table"
                  },
                  {
                    id: 7,
                    name: "Indor Fireplace"
                  },
                  {
                    id: 8,
                    name: "Outdoor dinig"
                  },
                  {
                    id: 9,
                    name: "Exersice Equipment"
                  }
                ]}
              />
              <SelectBoxChous
                titel={"What about these guest favorites "}
                data={[
                  {
                    id: 1,
                    name: "Wifi"
                  },
                  {
                    id: 2,
                    name: "TV"
                  },
                  {
                    id: 3,
                    name: "Kitchen"
                  },
                  {
                    id: 4,
                    name: "Washer"
                  },
                  {
                    id: 5,
                    name: "Free Parking"
                  },
                  {
                    id: 6,
                    name: "Paid Parking"
                  },
                  {
                    id: 7,
                    name: "Air Conditioning"
                  },
                  {
                    id: 8,
                    name: "Workspace "
                  },
                  {
                    id: 9,
                    name: "Outdoor Shower "
                  }
                ]}
              />
            </> : null}

            {Steps == 3 ? <>
              <MultiSelect />
            </> : null}

            {Steps == 4 ? <>
              <MultiUploud />
            </> : null}
            {Steps == 5 ? <>
              <SectionDetails />
            </> : null}

            {Steps == 6 ? <>
              <SelectPointPosition />
            </> : null}


          </div>
          <div className='ButtomSectionBtn'>
            <Row style={{
              justifyContent: "space-between",
              textAlign: "center"
            }}>
              <Col lg={6} md={6} xs={6}>
                <a className='btnBack btn_' onClick={(e) => SetSteps(Steps - 1)}>Back</a>
              </Col>
              <Col lg={6} md={6} xs={6}>
                <a className='btnNext btn_' onClick={(e) => Steps != 6 ? SetSteps(Steps + 1) : navigate(`/`)}>Next</a>
              </Col>
            </Row>

          </div>
        </Container>
      </>}


    </Styled>
  )
}
export default Hoster