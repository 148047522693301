import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
:root {
  --main_color: #42d495;
  --font_globale:"Gilroy-Medium";
  --second_color:""
}
@font-face {
  font-family: "Gilroy-Black"; 
  src: local("Gilroy-Black"),
    url("./fonts/Gilroy-Black.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-BlackItalic"; 
  src: local("Gilroy-BlackItalic"),
    url("./fonts/Gilroy-BlackItalic.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Bold"; 
  src: local("Gilroy-Bold"),
    url("./fonts/Gilroy-Bold.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-BoldItalic"; 
  src: local("Gilroy-BoldItalic"),
    url("./fonts/Gilroy-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-ExtraBold"; 
  src: local("Gilroy-ExtraBold"),
    url("./fonts/Gilroy-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Heavy"; 
  src: local("Gilroy-Heavy"),
    url("./fonts/Gilroy-Heavy.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Light"; 
  src: local("Gilroy-Light"),
    url("./fonts/Gilroy-Light.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Medium"; 
  src: local("Gilroy-Medium"),
    url("./fonts/Gilroy-Medium.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Regular"; 
  src: local("Gilroy-Regular"),
    url("./fonts/Gilroy-Regular.woff") format("woff");
}


*{
    font-family: var(--font_globale) !important;
}
a{
  cursor: pointer !important;
}
html{
  overflow-x:hidden;
}

`;
 
export default GlobalStyle;