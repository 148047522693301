import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const Styled = styled.div`
button.btn.btn-primary{
    background-color: #42d495;
    border: #42d495;
    width: 100%;
    text-align: center;
    height: 45px;
}

`;
const ButtonStyle = (props) => {


  return (
    <Styled>
      <Button onClick={props.onClick} setLoding={props.setLoding} variant="primary" disabled={props.loding}>
        {props.loding ? <><Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />{"  " + props.text + " "}</> : props.text}
      </Button>
    </Styled>
  )
}

export default ButtonStyle