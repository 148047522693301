import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import FormReserve from '../widget/FormReserve';
const Styled = styled.div`
    padding-top: 80px;
    border-top: solid 1px #ccc;

    .Fr_01 h2 {
      font-size: 25px;
    font-weight: 800;
}
.OptionPay {
  padding: 5px 5px;
}
.op_ {
    border: solid;
    margin: 15px 0;
    padding: 10px 20px;
    border-width: 1px;
    border-radius: 20px;
    height: 100px;
    display: block;
    border-color: #ccc;
}
div#ds h2 {
    margin-top: 20px;
}
.op_.selected {
    border-width: 2px;
    border-color: #000;
}


.ccp {
  margin: auto;
    padding: 22px 18px;
    background-color: #f2f2f2;
    height: 107px;
    border-radius: 20px;
    width: 100%;
}
.ccp img {
    padding-top: 8px;
}
p {
    margin: 0;
}
a.btnUp {
    padding: 20px 10px;
    text-decoration: none;
    background-color: #42d495;
    color: #fff;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 12px;
}
.Ti{
  font-size: 30px !important;
}
`;

const Checkout = () => {
  const [optionPay,SetoptionPay] = useState(1)
  return (
  <Styled>
    <Container>
      <Row>
        <Col lg={6} xs={12} md={6} >
          <div className='Fr_01'>
            <h2 className='Ti'>Choose how to pay</h2>

            <div className='OptionPay'>
              <div onClick={(e)=>SetoptionPay(1)} className={optionPay==1?'op_ selected':'op_'}>
                <h2>Pay in full</h2>
                <p>Pay the total now and you're all set.</p>

              </div>
              <div onClick={(e)=>SetoptionPay(2)} id={"ds"} className={optionPay==2?'op_ selected':'op_'}>
                <h2>Pay part now,part later</h2>
  
              </div>
            </div>

            <h2 className='Ti'>Pay with</h2>
            <Row style={{
              alignItems:'center',
              justifyContent:'space-between',
              marginTop:30
            }}>
              <Col lg={2}>
                <div className='ccp'>
                <img src='https://moncompte.djezzy.dz/static/img/cib.bc8403a65f49.png'/>
                  </div>
              </Col>
              <Col lg={10}>
                <div className='ccp'>
                  <Row>
                    <Col>
                    <h2>CCP</h2>
                    <p>CCP number here</p>
                    </Col>
                    <Col>
                    <a className='btnUp' href='#'>Upload recu</a>
                    </Col>
                  </Row>

                </div>
                
                </Col>
            </Row>
          </div>

        </Col>
        <Col lg={6} xs={12} md={6} >
          <div className='SecCard'>
            <FormReserve chek={"df"}/>

          </div>
        </Col>
      </Row>

    </Container>

    </Styled>
  )
}

export default Checkout