import React from 'react'
import styled from 'styled-components';
import { BsPersonFill } from "react-icons/bs";

const Styled = styled.div`
    margin-left: auto;
    max-width: 300px;
    width: fit-content;
.Imag{
  width: 300px;
    height: 300px;
    position: relative;


}
img {
  width: 100%;
    display: block;
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 10px;
}
h2.Address {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding-top: 15px;
}
.Firset {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Firset span {
    line-height: 0;
    font-size: 16px;
    font-weight: 200 !important;
    margin-top: 16px;
}
.Firset span svg{
 
    width: 20px;
    height: 20px;
}
@media only screen and (max-width: 1390px) {
  .Imag{
    width: 200px;
    height: 200px;
  }
  h2.Address {
  font-size: 14px;
}
h1.Price {
  font-size: 12px;
}
.Firset span {
  font-size: 12px;
}
span.UserCard {
    font-size: 12px;
}
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .Imag{
    width: 150px;
    height: 150px;
}
h2.Address {
  font-size: 14px;
}
h1.Price {
  font-size: 12px;
}
.Firset span {
  font-size: 12px;
}
span.UserCard {
    font-size: 12px;
}
}
`;

const CardWilaya = (props) => {
  return (
    <Styled>

      <div className='Imag'>
        <a><img src={props.data.imagWilaya} /></a>
      </div>
      <div className='FooterCard'>
        <div className='Firset'>
          <h2 className='Address'>{props.data.wilaya}</h2><span><BsPersonFill />
            {props.data.numberUser}</span></div>
      </div>

    </Styled>
  )
}

export default CardWilaya