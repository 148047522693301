import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import formImag from '../imag/form.jpg'
import { DateRangePicker, View } from '@adobe/react-spectrum'
import { defaultTheme, Provider } from '@adobe/react-spectrum';
import { FiMapPin } from "react-icons/fi";
import wilayas from '../data/wilaya.json'
import SelectDesing from './SelectDesing';
import SelectOptions from './SelectOptions';
import { FiSearch } from "react-icons/fi";
const Styled = styled.div`
border-bottom: #e5e5e5 solid 1px;
border-top: #e5e5e5 solid 1px;
padding: 30px;
.Walpp {
    background-image: url(${formImag});
    max-height:540px;
    height: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.CardForm {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    background-color: #fff;
    padding: 25px;
    border-radius: 20px;
}
h1{
    font-weight: 600;
    font-size: 30px;
}


.svg{
    width: 23px;
    height: 23px;
    color: black;

}

/*.selectV > div:first-of-type {
    height: 50px !important;
    border: none !important;
    background-color: #f0f3f5 !important;
    border-radius: 10px !important;
}*/

.btnSerch{
    padding: 15px 40px;
    background-color: #42d495;
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}
.btnSerch svg{
    margin-right: 5px;
    width: 23px;
    height: 23px;
    margin-top: auto;

}
@media only screen and (max-width: 1250px) {
    .btnSerch{
    padding: 15px 20px;
    background-color: #42d495;
    border-radius: 8px;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
}
.btnSerch svg{
    margin-right: 5px;
    width: 20px;
    height: 20px;
    margin-top: auto;

}

}
@media only screen and (max-width: 768px) {
    .CardForm {
        width: 90%;
    }
    .align-items-center.row >div {
    padding: 10px;
}
a.btnSerch {
    width: -webkit-fill-available;
    max-width: 100%;
    display: block;
    text-align: center;
}
}
.spectrum-InputGroup_a0942f{
    width: 100% !important;
    background-color: transparent;
   
}
[dir=ltr] .spectrum-InputGroup-input_a0942f{
    border: none;
    height: 45px;

}
.sectionInput div{
    background-color: #f5f5f5;
    border-radius: 8px !important;
    border: solid 1px #f5f5f5;
    display: inline-flex;

}
.sectionInput {
    background-color: #fff;
}
button{
    border: none !important;
    background-color: transparent;
    margin-top: 7px;
}
`;
const FormSerchF = () => {
    return (
        <Styled>
                <Container>
                        <Row className="align-items-center" >
                             <Col sm={6} lg={3}>
                             <SelectDesing  icon={<FiMapPin/>} label={"Wilaya"} options={wilayas}/>
                            </Col>
                            <Col sm={6} lg={3}>
                            <Provider colorScheme="light" theme={defaultTheme}>
                        <div className='sectionInput'><DateRangePicker className="DatePicker" />
                        </div>
                    </Provider>
                            </Col>
                            <Col sm={3} lg={2}>
                            <SelectOptions name='Rooms'/>
                            </Col>
                            <Col sm={3} lg={2}>
                            <SelectOptions name='Geusts'/>
                            </Col>
                            <Col sm={3} lg={2}>
                                <a className='btnSerch'><FiSearch/>Search</a>
                            </Col>
                        </Row>



                </Container>

        </Styled>
    )
}

export default FormSerchF