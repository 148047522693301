import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import InputMap2 from './InputMap2';
import SelectDesing from './SelectDesing';
import { FiMapPin } from "react-icons/fi";
import wilayas from '../data/wilaya.json'
const Styled = styled.div`


`;



const SelectPointPosition = () => {
    const [position, Setposition] = useState({
        lat: '',
        lng: ''
    });
    return (
        <Styled>
            <Row style={{
                alignItems: "center"
            }}>
                <Col lg={6} xs={12}>
                    <h2>Where's your place located ?</h2>
                    <SelectDesing icon={<FiMapPin />} label={"Wilaya"} options={wilayas} />
                    <p>Then choose the location on the map</p>
                </Col>
                <Col lg={6} xs={12}>
                    <InputMap2 position={position} Setposition={Setposition} height={"400"} />
                </Col>
            </Row>


        </Styled>
    )
}

export default SelectPointPosition