
import React from 'react';
import styled from 'styled-components';
import Header from '../widget/Header';
const Styled =  styled.div`

`;

const Layout = (props) => {
  return (
    <Styled>
        <Header/>
        {props.children}
    </Styled>
  )
}

export default Layout