import React, { useState } from 'react';
import styled from 'styled-components';
import { BsFillBrushFill } from "react-icons/bs";

const Styled = styled.div`
padding-bottom: 10px;
.ListOption {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    height: 100%;
    max-width: 800px;
    margin: auto;
}
._Item {
    width: 250px;
    height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #f5f5f5;
    margin: 10px 0;
    border-radius: 20px;
}
h2 {
    text-align: center;
}
.selected{
    border: solid 1px #ccc;
}
`;

const SelectBoxChous = (props) => {
    const [Select,SetSelect] = useState([]);
    const add = (id) => {
        SetSelect(oldArray => [...oldArray, id]);

    }
    const remove = (id)=>{
        SetSelect(Select.filter(item => item !== id))
    }

  return (
    <Styled>
        <div className='SectionFirst'>
            <h2> {props.titel} </h2>
            <div className='ListOption'>
            {
            props.data.map((item,i)=><div onClick={(e)=>Select.find(x=> x == item.id)?remove(item.id):add(item.id)} className={Select.find(x=> x == item.id)?'_Item selected':'_Item'}>
            <div  className='icon'>
                <BsFillBrushFill/>
            </div>
            <span className='Titel'>{item.name}</span>
        </div>)
            
            }
            </div>
        </div>

    </Styled>
  )
}

export default SelectBoxChous