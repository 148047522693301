import React from 'react';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
const Styled = styled.div`
    margin: 15px 0;
div{
    width: 100%;
    justify-content: space-around;
}
input{
    border: none;
    width: 100% !important;
    height: 45px;
    background-color: #f0f3f5;
    border-radius: 5px;
    max-width: 45px;
    
}

`;

const OtpInputStyle = (prpos) => {
  return (
    <Styled>
        <OtpInput
        value={prpos.otp}
        onChange={prpos.handleChange}
        numInputs={6}
        separator={<span></span>}
      />
    </Styled>
  )
}

export default OtpInputStyle