import React, { useState } from 'react'
import { RMIUploader } from "react-multiple-image-uploader";
import styled from 'styled-components';
const Styled = styled.div`
.ant-col.ant-col-14 {
    display: none;
}
.ant-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.upload__image-wrapper {
    padding: 20px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
}
.ant-card.ant-card-bordered {
    border: none;
}
h2{
  text-align: center;
}
`;
const MultiUploud = () => {
    const [visible, setVisible] = useState(false);
    const handleSetVisible = () => {
      setVisible(true);
    };
    const hideModal = () => {
      setVisible(false);
    };
    const onUpload = (data) => {
      console.log("Upload files", data);
    };
    const onSelect = (data) => {
      console.log("Select files", data);
    };
    const onRemove = (id) => {
      console.log("Remove image id", id);
    };
  return (
    <Styled>
      <h2>let's add some photos of your place</h2>
  <RMIUploader
       warnMessage='sd'
        onSelect={onSelect}
        onUpload={onUpload}
        onRemove={onRemove}
      />
    </Styled>
  )
}

export default MultiUploud