import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ButtonStyle from '../widget/ButtonStyle';
import InputDesing from '../widget/InputDesing';
import OtpInputStyle from '../widget/OtpInput';
import PhoneInputStyle from '../widget/PhoneInput';
import wilayas from '../data/wilaya.json'
import { FiUser,FiMail,FiMapPin,FiCalendar } from "react-icons/fi";
import SelectDesing from '../widget/SelectDesing';
const Styled = styled.div`
.FormOtp{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 400px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin: auto;
    border: #d5d5d5;
    border-width: 1px;
    border-style: solid;
   } 
   h2{
    font-size: 18px;
    font-weight: 900;
   }
   p {
    font-size: 14px;
    margin-top: 18px;
    color:  #a7a7a7;
}
.SectionSfg.row >div {
    margin: 5px 0;
}
`;
const Login = () => {
    const [loding, SetLoding] = useState(0);
    const [Setep, SetSetep] = useState(1);
    const [Phone,SetPhone] = useState();
    const [OtpCode,SetOtpCode] = useState();
    const [FormValues,SeFormValues] = useState({
        FullName:"",
        Email:"",
        Wilaya:"",
        Birthday:"",
        Sex:1
    });

    const SandOtp = () => {
        SetLoding(1)
        setTimeout(function () {
            SetLoding(0);
            SetSetep(2);
        }, 1000)
    }

    const ConformOtp = () => {
        SetLoding(1)
        setTimeout(function () {
            SetLoding(0);
            SetSetep(3);
        }, 1000)
    }


    return (
        <Styled>
            <div className='FormOtp'>
               
                {
                    Setep=="1"? <>
                    <h2>Login</h2>
                    <p>Pleas enter your phone number and follow the steps to login to your account</p>
                    <PhoneInputStyle value={Phone} onChange={SetPhone}/>
                    <ButtonStyle text={"Login"} loding={loding} setLoding={SetLoding} onClick={SandOtp} />
                </>:null
                }
                {
                    Setep=="2"? <>
                    <h2>Login</h2>
                    <p>Enter the code generated on your mobile device below to log in!</p>
                    <OtpInputStyle otp={OtpCode} handleChange={(e)=>SetOtpCode(e)}/>
                    <ButtonStyle text={"Confirm"} loding={loding} setLoding={SetLoding} onClick={ConformOtp} />
                </>:null
                }
                {
                    Setep=="3"? <>
                    <h2>Your Information</h2>
                    <Row className='SectionSfg'>
                        <Col lg={12}>
                        <InputDesing type="text" value={FormValues.FullName} Setvalue={(value)=>SeFormValues({...FormValues,FullName:value})} icon={<FiUser/>} />
                        </Col>
                        <Col lg={12}>
                        <InputDesing  type="text" value={FormValues.Email} Setvalue={(value)=>SeFormValues({...FormValues,Email:value})}  icon={<FiMail/>} />
                        </Col>
                        <Col lg={12}>
                        <SelectDesing  icon={<FiMapPin/>} options={wilayas}/>
                        </Col>
                        <Col lg={12}>
                        <InputDesing  type="date"  icon={<FiCalendar/>}/>
                        </Col>
                        <Col lg={12}>
                        <SelectDesing  icon={<FiUser/>} options={[{
                            "value":"1",
                            "label":"Male"
                        }]}/>
                        </Col>
                    </Row>
                    <br/>
                 

                    <ButtonStyle text={"Confirm"} loding={loding} setLoding={SetLoding} onClick={ConformOtp} />
                </>:null
                }
            </div>
        </Styled>
    )
}

export default Login