import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styled from 'styled-components';
const Styled = styled.div`
.react-tel-input .form-control {
    border: none;
    background-color: transparent; 
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
    height: 45px;
  /*  height: 50px;
    margin: 15px 0;
 
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;*/
}
.react-tel-input .flag-dropdown{
    border: none !important;
    background-color: transparent;
}
.react-tel-input {
    height: 45px;
    margin: 15px 0;
    background-color: #f0f3f5; 
    border-radius: 10px;
    padding: 0 15px;
}
.react-tel-input .flag-dropdown.open .selected-flag ,.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background: #fff0;
}
`;
const PhoneInputStyle = (prpos) => {
  return (
    <Styled>
    <PhoneInput
      onlyCountries={['fr', 'dz']}
      placeholder="Enter phone number"
      country={'dz'}
      value={prpos.value}
      onChange={prpos.onChange}/>
    </Styled>
  )
}

export default PhoneInputStyle