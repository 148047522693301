import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import play from '../imag/Google Play PNG.png';
import App from '../imag/App Strore Png.png';
import imag from '../imag/logo3.png';

import Phone from '../imag/Phone2.png'
const Styled = styled.div`
padding: 20px 0;
h1 {
    font-size: 46px;
    font-weight: 800;
}
h1 span {
    color: #42d495;
}
.SectionImag{
    position: relative;
}
img.Modal {
    position: absolute;
    right: 6%;
    max-width: 260px;
    top: -55px;
}
.SectionImag {
    padding: 60px 10%;
    background-color: #42d495;
    border-radius: 20px;
    margin: 30px 0;
}
h2 {
    max-width: 600px;
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 40px;
}
.logo{
  filter: brightness(0) invert(1);
}
img.logo {
    margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
    img.Modal {
        display: none;
    }
    h1 {
    font-size: 30px;
    font-weight: 800;
}
h2 {
    max-width: 600px;
    font-size: 25px;
}
}
`;

const SectionApp = () => {
    return (
        <Styled>
            <Container>
                <h1>Get the <span>Achouche Tours</span> app</h1>
                <div className='SectionImag'>
                    <img className='logo' height={40} src={imag} />
                    <h2>Enjoy benefits only avaible in our award winning app.</h2>
                    <Row style={{
                        maxWidth: "300px"
                    }}>
                        <Col xs={6} lg={6}>
                            <a><img height={40} src={play} /></a>
                        </Col>
                        <Col xs={6} lg={6}>
                            <a><img height={40} src={App} /></a>
                        </Col>
                    </Row>
                    <img className='Modal' src={Phone} />
                </div>

            </Container>
        </Styled>
    )
}

export default SectionApp