import React from 'react';
import styled from 'styled-components';
import Footer from '../widget/Footer';
import FormSerche from '../widget/FormSerche';
import SectionApp from '../widget/SectionApp';
import SectionFirset from '../widget/SectionFirset';
import SectionMost from '../widget/SectionMost';

const Styled = styled.div`

`;

const Home = () => {
  return (
    <Styled>
        <FormSerche/>
        <SectionFirset/>
        <SectionMost/>
        <SectionApp/>
        <Footer/>
    </Styled>
  )
}

export default Home