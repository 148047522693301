import React from 'react'
import styled from 'styled-components';
import { FiHeart } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";
import { Link } from 'react-router-dom';
const Styled = styled.div`
    margin-left: auto;
    max-width: 300px;
    width: fit-content;
.Imag{
     width: 300px;
    height: 300px;
    position: relative;
}

img {
  width: 100%;
    display: block;
    object-fit: cover;
    height: -webkit-fill-available;
    border-radius: 10px;
}
h2.Address {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding-top: 15px;
}

h1.Price {
    font-size: 18px;
    font-weight: 600;
}
a.FavIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    width: 40px;
    height: 40px;
    background-color: #efefef8c;
    border-radius: 20px;
}
a.FavIcon svg {
    margin: 7px 7px;
    width: 25px;
    height: 25px;
}
.Firset {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Firset span {
    line-height: 0;
    font-size: 16px;
    font-weight: 200 !important;
}
.Firset span svg{
  color:#ffbe0d;
    width: 20px;
    height: 20px;
}
@media only screen and (max-width: 1390px) {
  .Imag{
    width: 200px;
    height: 200px;
  }
  h2.Address {
  font-size: 14px;
}
h1.Price {
  font-size: 12px;
}
.Firset span {
  font-size: 12px;
}
span.UserCard {
    font-size: 12px;
}
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .Imag{
    width: 150px;
    height: 150px;
}
h2.Address {
  font-size: 14px;
}
h1.Price {
  font-size: 12px;
}
.Firset span {
  font-size: 12px;
}
span.UserCard {
    font-size: 12px;
}
}
`;
const CardHouse = (props) => {
  return (
    <Styled>
      <div className='Imag'>
        <a className='FavIcon'><FiHeart /></a>
        <Link to={`/House`}><img src={props.data.imag} /></Link>
      </div>
      <div className='FooterCard'>
        <div className='Firset'>
          <h2 className='Address'>{props.data.addres}</h2><span><AiFillStar /> {props.data.star}</span></div>
        <span className='UserCard'>Hosted by {props.data.user}</span>
        <h1 className='Price'> {props.data.price} DA</h1>
      </div>

    </Styled>
  )
}

export default CardHouse