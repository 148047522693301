import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import CardWilaya from './CardWilaya';
import StyleHeroText from './StyleHeroText';
import ListCard from '../data/FakeDataHouse.json'
const Styled = styled.div`
margin: 60px 0;

`;

const SectionMost = () => {
    return (
        <Styled>
            <Container>
                <StyleHeroText text={"Top popular appartements,Houses"} sup="See what's popular with other travelers" />
                <Row>
                    {
                        ListCard.map((item, i) => i < 4 ? <Col lg={3} md={4} xs={6} key={i}><CardWilaya data={item} /></Col> : null)
                    }

                </Row>
            </Container>

        </Styled>
    )
}

export default SectionMost