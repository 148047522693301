import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';

const Styled = styled.div`
    background-color: #f0f3f5;
    height: 50px;
    border-radius: 10px;
span#basic-addon {
    background-color: transparent;
    border: none;
}
input.form-control {
    border: none;
    height: 50px;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
}

`;

const InputDesing = (prpos) => {
    return (
        <Styled>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon">{prpos.icon}</InputGroup.Text>
                <Form.Control
                    type={prpos.type}
                    value={prpos.value}
                    onChange={(e)=>prpos.Setvalue(e.target.value)}
                    placeholder={prpos.placeholder}
                    aria-label={prpos.placeholder}
                    aria-describedby="basic-addon"
                />
            </InputGroup>
        </Styled>
    )
}

export default InputDesing