import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
const Styled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 50px;
    background-color: #f0f3f5;
    border-radius: 10px;
   .Vinput input{
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
    text-align: center;
    width: 80px;
    font-size: 12px;
   } 
   .Lbtn ,.Rbtn {
    width: 30px;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: #212529;

}

`;
const SelectOptions = (props) => {
    const [val, SetVal] = useState(1);
    return (
        <Styled>
            <a className='Lbtn' onClick={(e) => SetVal(val + 1)}>+</a>
            <div className='Vinput'>
                <Form.Control readOnly value={props.name + " " + val}/>
            </div>
            <a className='Rbtn' onClick={(e) => val > 1 ? SetVal(val - 1) : null} >-</a>
        </Styled>
    )
}

export default SelectOptions