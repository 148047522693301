import React from 'react';
import styled from 'styled-components';
const Styled = styled.div`
h2{
  font-weight: 600;
}
`;
const StyleHeroText = (props) => {
  return (
    <Styled>
        <h2>{props.text}</h2>
        {
           props.sup && props.sup!=""?<p>{props.sup}</p>:null
        }
    </Styled>
  )
}

export default StyleHeroText